<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>
          Terms of Service
        </h1>
        <p>Last Update: July 2022</p>

        <h2>Introduction To Terms Of Service</h2>
        <p>
          Adhere.ly provides this website ("Web Site") to you for your educational use. 
          The following Terms of Service govern your access and use of this Web Site. 
          Please read these Terms of Service carefully before using this Web Site. 
          Further, specific terms and conditions may apply to specific Content 
          (as defined below) on or available through this Web Site (the "Content") 
          and to specific content, materials, information or data you may upload, 
          submit and/or publish to the Web Site ("User Content") or transactions 
          conducted through this Website. Such specific terms may be in addition to 
          these Terms of Service or, where and then only to the extent expressly 
          specified, may supersede these Terms of Service. When used in these Terms 
          of Service, "we" and "our" means Adhere.ly, and "you" and "your" refers to 
          any individual, company or legal entity that accesses or otherwise uses this 
          Web Site.
        </p>

        <h2>Acceptance of Terms of Service</h2>
        <p>
          By accessing, browsing or using this Website, you agree to be bound 
          by these Terms of Service and all terms and conditions 
          referenced or contained herein or any additional terms and 
          conditions set forth on this Web Site. If you do NOT agree to 
          all of the terms set forth here, you should NOT access or use 
          this Web Site.
        </p>
 
        <h2>Modification of Terms</h2>
        <p>
          These Terms of Service may be amended by Adhere.ly, in its sole 
          discretion, at any time without notice to you. Such amended Terms of 
          Use shall be effective upon posting. By continuing to access or use 
          this Web Site, you will be deemed to have accepted such amendments. 
          You are advised to regularly review any applicable terms and conditions.
        </p>
        <p>
          Adhere.ly reserves the right to discontinue or make changes or updates 
          with respect to the Web Site or the Content of the Web Site at any time 
          and without notice.
        </p>

        <h2>Links to Third Party Websites</h2>
        <p>
          Links on the Web Site to third party websites are provided only as a 
          convenience to you. If you use these links, you will leave the Web Site. 
          Adhere.ly does not endorse or control any such third party websites. You 
          agree that Adhere.ly and its affiliates will not be responsible or liable 
          for any content, goods or services provided on or through these outside 
          websites or for your use or inability to use such websites. These links will 
          be used at your own risk. You are advised that other websites on the 
          Internet, including third party websites linked from this Web Site, 
          might contain material or information that some people may find 
          offensive or inappropriate. Further, such information may be inaccurate, 
          misleading, untrue, or deceptive, libellous, defamatory or infringing of 
          others' rights or otherwise unlawful. Adhere.ly expressly disclaims any 
          responsibility for the content, accuracy, legality or decency of any 
          information, and for any services or products that appear on any third 
          party website. Adhere.ly recommends that you make yourself aware of and 
          read the privacy and legal notices of all other websites that you visit.
        </p>
      
        <h2>Privacy Notice</h2>
        <p>
          Personal information provided through this Web Site shall be used in 
          accordance with Adhere.ly’s Privacy Notice. These Terms of Service are 
          subject to the Privacy Notice as posted on this Web Site.
        </p>
      
        <h2>Disclaimer of Warranties</h2>
        <p>
          Adhere.ly strives to provide accurate and up-to-date material on this 
          Web Site. However, we make no warranties or representations as to the 
          accuracy or timeliness of its Content.
        </p>
        <p>
          Your use of this website is at your sole risk. The website and the 
          content contained within it are provided on an "as is" and "as available" 
          basis. Adhere.ly expressly disclaims all warranties of any kind, including, 
          but not limited to the implied warranties of merchantability, fitness for a 
          particular purpose and non-infringement.adhere.ly makes no warranty that 
          (i) the website will meet your requirements, (ii) the website will be 
          uninterrupted, timely, secure, or error-free, (iii) the results that 
          may be obtained from the use of the website will be accurate or reliable, 
          (iv) the website is free from viruses or other harmful components, or (v) 
          any errors in the website will be corrected. any material downloaded or 
          otherwise obtained through this website is provided at your own risk, 
          and you are solely responsible for any damage to your computer system 
          or loss of data that results from your use of the website, including 
          without limitation, damages resulting from computer viruses.
        </p>
        <p>
          Some jurisdictions may not permit certain disclaimers of warranties. 
          Therefore some of the exclusions above may not apply to you. In such 
          jurisdictions, we disclaim warranties to the fullest extent permitted 
          by applicable law.
        </p>
      
        <h2>Disclaimer of Warranties</h2>
        <p>
          NEITHER Adhere.ly or its affiliates shall under any circumstances be 
          liable for any damages of any kind arising out of, in connection with 
          or relating to your access to, or use of or inability to use this 
          website or any material, or any other website you access through a 
          link from this website or any incorrect or inaccurate information on 
          this website. This is a comprehensive limitation of liability that 
          applies to all damages of any kind, including any direct, indirect, 
          special, incidental or consequential damages (including but not limited 
          to property damage, loss of use, loss of data, economic loss, loss of 
          business or loss of profits), whether based on breach of contract, 
          breach of warranty, tort (including negligence) or otherwise, even if 
          Adhere.ly has been advised of the possibility of such damage or loss. 
          To the maximum extent permitted by applicable law, you expressly waive 
          all claims against Adhere.ly and its affiliates and their officers, 
          directors, employees, suppliers and programmers that may arise from 
          your access or use of this site.
        </p>

        <h2>Indemnification and Release</h2>
        <p>
          You agree to indemnify, defend and hold harmless Adhere.ly and its 
          affiliates against all claims, demands, causes of action, losses, 
          expenses, damages and costs, including any reasonable attorneys' 
          fees, resulting or arising from or relating to your use of or conduct 
          on the Web Site, any activity related to use of the Web Site by you, 
          any message or material that you submit to, post on or transmit through 
          the Web Site, your violation of these Terms of Service, your infringement 
          or violation of any rights of another, or termination of your access to 
          the Web Site.
        </p>

        <h2>No Unlawful or Prohibited Activity</h2>
        <p>
          As a condition of your use of this Web Site, you agree not to use the 
          Web Site for any purpose that is unlawful or prohibited by these terms 
          and conditions. You further agree that you are responsible for your use 
          of and communications on the Web Site. You agree not to post on or 
          transmit through this Web Site any unlawful, infringing, threatening, 
          harassing, defamatory, vulgar, obscene, profane, indecent, offensive, 
          hateful or otherwise objectionable material of any kind, including any 
          material that encourages criminal conduct or conduct that would give 
          rise to civil liability, violates the privacy rights of others, infringes 
          others' intellectual property rights or otherwise violates any applicable 
          local, state, national or international law. You agree not to use this 
          Web Site in any manner that interferes with its normal operation or with 
          any other user's use and enjoyment of the Site. You agree to use 
          reasonable efforts to scan and remove any viruses or other contaminating 
          or destructive features before submitting any material.
        </p>
        <p>
          Adhere.ly reserves the right, in its sole discretion, to suspend or 
          terminate your access to this Web Site and prohibit any and all current 
          and future use of this Web Site (or any portion thereof) by you, if you 
          fail to comply with any term or provision of these Terms of Service or 
          your use is harmful to the interests of another user of this Web Site.
        </p>
      
        <h2>Governing Law and Dispute Resolution</h2>
        <p>
          Adhere.ly makes no representations that the material and information 
          on this Site are appropriate or available in all national locations or 
          languages. You agree that any action at law or in equity arising from 
          or relating to the use of this Web Site or to these Terms of Use shall 
          be brought exclusively in the Federal or State Courts. You hereby consent 
          and submit to personal jurisdiction in of such courts for the purposes 
          of any action relating to this Web Site, your access or use thereof, or 
          these Terms of Service, and to extra-territorial service of process.
        </p>
        <p>
          If any provision of these Terms of Service is held to be unlawful, 
          void, or for any reason unenforceable by a court of competent 
          jurisdiction, then the invalid or unenforceable provision shall be 
          replaced by a valid, enforceable provision that most closely matches 
          the intent of the original provision, and the validity and enforceability 
          of any remaining provisions shall not be affected.
        </p>

        <h2>No Waiver</h2>
        <p>
          The failure of Adhere.ly and its affiliates to enforce any part of 
          these Terms of Service shall not constitute a waiver of such term or 
          provision, and shall not be considered a waiver or limit Adhere.ly’s 
          right thereafter to insist upon strict adherence to that term or any 
          other provision of these Terms of Service.
        </p>
      
        <h3>
          YOU CONTINUING TO USE THIS WEBSITE MEANS YOU AGREE TO OUR TERMS OF 
          SERVICE
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  head: {
    title: {
      inner: 'Terms of Service'
    },
    meta: [
      {
        name: 'description',
        content: 'Terms of Service',
        id: 'desc'
      }
    ]
  }
}
</script>

<style scoped>

h1 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 28px;
  line-height: 1;
  margin-bottom: 1rem;
}

h2 {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 26px;
  line-height: 1;
  margin-bottom: 1rem;
}

h3 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 1rem;
}

h4 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0.75rem;
}

h5 {
  color: #ACB5BD;
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 1rem;
}

p {
  color: #0C0C0C;
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

</style>